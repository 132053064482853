var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length > 0
    ? _c(
        "div",
        { staticClass: "my-5" },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "v-row",
            {
              key: index,
              staticClass: "mb-2 mb-sm-1 mt-0",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-left mew-caption font-weight-medium greyPrimary--text",
                  attrs: { cols: "12", sm: "6" },
                },
                [_vm._v(" " + _vm._s(item) + " ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-sm-right",
                  attrs: { cols: "12", sm: "6" },
                },
                [_vm._t("rightColItem" + index)],
                2
              ),
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }